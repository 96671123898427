type TWhatYouGet = Record<string, string>[];

export const whatYouGet: TWhatYouGet = [
  {
    icon: "book",
    text: "Recent news on plant-based eating",
  },
  {
    icon: "find",
    text: "Research and scientific updates",
  },
  {
    icon: "refresh",
    text: "Be first to hear about research grants and awards",
  },
];
