export const countries = {
  3327066: "Afghanistan",
  3327067: "Albania",
  3327068: "Algeria",
  3327069: "American Samoa",
  3327070: "Andorra",
  3327071: "Angola",
  3327311: "Anguilla",
  3327312: "Antigua & Barbuda",
  3327313: "Argentina",
  3327314: "Armenia",
  3327315: "Aruba",
  3327316: "Australia",
  3327305: "Austria",
  3327306: "Azerbaijan",
  3327307: "Azores",
  3327308: "Bahamas",
  3327309: "Bahrain",
  3327310: "Bangladesh",
  3327299: "Barbados",
  3327300: "Belarus",
  3327301: "Belgium",
  3327302: "Belize",
  3327303: "Benin",
  3327304: "Bermuda",
  3327293: "Bhutan",
  3327294: "Bolivia",
  3327295: "Bonaire",
  3327296: "Bosnia & Herzegovina",
  3327297: "Botswana",
  3327298: "Brazil",
  3327287: "British Indian Ocean Ter",
  3327288: "Brunei",
  3327289: "Bulgaria",
  3327290: "Burkina Faso",
  3327291: "Burundi",
  3327292: "Cambodia",
  3327281: "Cameroon",
  3327282: "Canada",
  3327283: "Canary Islands",
  3327284: "Cape Verde",
  3327285: "Cayman Islands",
  3327286: "Central African Republic",
  3327275: "Chad",
  3327276: "Channel Islands",
  3327277: "Chile",
  3327278: "China",
  3327279: "Christmas Island",
  3327280: "Cocos Island",
  3327269: "Colombia",
  3327270: "Comoros",
  3327271: "Congo",
  3327272: "Congo Democratic Rep",
  3327273: "Cook Islands",
  3327274: "Costa Rica",
  3327263: "Cote D'Ivoire",
  3327264: "Croatia",
  3327265: "Cuba",
  3327266: "Curacao",
  3327267: "Cyprus",
  3327268: "Czech Republic",
  3327257: "Denmark",
  3327258: "Djibouti",
  3327259: "Dominica",
  3327260: "Dominican Republic",
  3327261: "East Timor",
  3327262: "Ecuador",
  3327251: "Egypt",
  3327252: "El Salvador",
  3327253: "Equatorial Guinea",
  3327254: "Eritrea",
  3327255: "Estonia",
  3327256: "Ethiopia",
  3327245: "Falkland Islands",
  3327246: "Faroe Islands",
  3327247: "Fiji",
  3327248: "Finland",
  3327249: "France",
  3327250: "French Guiana",
  3327239: "French Polynesia",
  3327240: "French Southern Ter",
  3327241: "Gabon",
  3327242: "Gambia",
  3327243: "Georgia",
  3327244: "Germany",
  3327233: "Ghana",
  3327234: "Gibraltar",
  3327235: "Great Britain",
  3327236: "Greece",
  3327237: "Greenland",
  3327238: "Grenada",
  3327227: "Guadeloupe",
  3327228: "Guam",
  3327229: "Guatemala",
  3327230: "Guernsey",
  3327231: "Guinea",
  3327232: "Guinea-Bissau",
  3327221: "Guyana",
  3327222: "Haiti",
  3327223: "Honduras",
  3327224: "Hong Kong - SAR of China",
  3327225: "Hungary",
  3327226: "Iceland",
  3327215: "India",
  3327216: "Indonesia",
  3327217: "Iran",
  3327218: "Iraq",
  3327219: "Ireland",
  3327220: "Isle of Man",
  3327209: "Israel",
  3327210: "Italy",
  3327211: "Jamaica",
  3327212: "Japan",
  3327213: "Jersey",
  3327214: "Jordan",
  3327203: "Kazakhstan",
  3327204: "Kenya",
  3327205: "Kiribati",
  3327206: "Korea North",
  3327207: "Korea South",
  3327208: "Kuwait",
  3327197: "Kyrgyzstan",
  3327198: "Laos",
  3327199: "Latvia",
  3327200: "Lebanon",
  3327201: "Lesotho",
  3327202: "Liberia",
  3327191: "Libya",
  3327192: "Liechtenstein",
  3327193: "Lithuania",
  3327194: "Luxembourg",
  3327195: "Macau - SAR of China",
  3327196: "North Macedonia",
  3327185: "Madagascar",
  3327186: "Malawi",
  3327187: "Malaysia",
  3327188: "Maldives",
  3327189: "Mali",
  3327190: "Malta",
  3327179: "Marshall Islands",
  3327180: "Martinique",
  3327181: "Mauritania",
  3327182: "Mauritius",
  3327183: "Mayotte",
  3327184: "Mexico",
  3327173: "Midway Islands",
  3327174: "Moldova",
  3327175: "Monaco",
  3327176: "Mongolia",
  3327177: "Montenegro",
  3327178: "Montserrat",
  3327167: "Morocco",
  3327168: "Mozambique",
  3327169: "Myanmar",
  3327170: "Namibia",
  3327171: "Nauru",
  3327172: "Nepal",
  3327161: "Netherland Antilles",
  3327162: "Netherlands",
  3327163: "Nevis",
  3327164: "New Caledonia",
  3327165: "New Zealand",
  3327166: "Nicaragua",
  3327155: "Niger",
  3327156: "Nigeria",
  3327157: "Niue",
  3327158: "Norfolk Island",
  3327159: "Norway",
  3327160: "Oman",
  3327149: "Pakistan",
  3327150: "Palau Island",
  3327151: "Palestine",
  3327152: "Panama",
  3327153: "Papua New Guinea",
  3327154: "Paraguay",
  3327143: "Peru",
  3327144: "Philippines",
  3327145: "Pitcairn Island",
  3327146: "Poland",
  3327147: "Portugal",
  3327148: "Puerto Rico",
  3327137: "Qatar",
  3327138: "Reunion",
  3327139: "Romania",
  3327140: "Russia",
  3327141: "Rwanda",
  3327142: "Saipan",
  3327131: "Samoa",
  3327132: "Samoa American",
  3327133: "San Marino",
  3327134: "Sao Tome & Principe",
  3327135: "Saudi Arabia",
  3327136: "Senegal",
  3327125: "Serbia",
  3327126: "Serbia & Montenegro",
  3327127: "Seychelles",
  3327128: "Sierra Leone",
  3327129: "Singapore",
  3327130: "Slovakia",
  3327119: "Slovenia",
  3327120: "Solomon Islands",
  3327121: "Somalia",
  3327122: "South Africa",
  3327123: "South Sudan",
  3327124: "Spain",
  3327113: "Sri Lanka",
  3327114: "St Barthelemy",
  3327115: "St Eustatius",
  3327116: "St Helena",
  3327117: "St Kitts-Nevis",
  3327118: "St Lucia",
  3327107: "St Maarten",
  3327108: "St Pierre & Miquelon",
  3327109: "St Vincent & Grenadines",
  3327110: "Sudan",
  3327111: "Suriname",
  3327112: "Swaziland",
  3327101: "Sweden",
  3327102: "Switzerland",
  3327103: "Syria",
  3327104: "Tahiti",
  3327105: "Taiwan - China",
  3327106: "Tajikistan",
  3327095: "Tanzania",
  3327096: "Thailand",
  3327097: "Togo",
  3327098: "Tokelau",
  3327099: "Tonga",
  3327100: "Trinidad & Tobago",
  3327089: "Tunisia",
  3327090: "Turkey",
  3327091: "Turkmenistan",
  3327092: "Turks & Caicos Is",
  3327093: "Tuvalu",
  3327094: "Uganda",
  3327083: "Ukraine",
  3327084: "United Arab Emirates",
  3327085: "United Kingdom",
  3327086: "United States of America",
  3327087: "Uruguay",
  3327088: "Uzbekistan",
  3327077: "Vanuatu",
  3327078: "Vatican City State",
  3327079: "Venezuela",
  3327080: "Vietnam",
  3327081: "Virgin Islands (Brit)",
  3327082: "Virgin Islands (USA)",
  3327072: "Wake Island",
  3327073: "Wallis & Futana Is",
  3327074: "Yemen",
  3327075: "Zambia",
  3327076: "Zimbabwe",
};
